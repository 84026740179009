import { useState, useCallback, useEffect } from 'react';
import { A11y, Navigation, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ProductItem } from '../ProductItem';
import { Spinner } from '../Spinner';
import { Svg } from '../Svg';
import { useColorSwatches } from '../../hooks';

export function ProductSimilarRecommendations({
  similarProducts,
  legacyResourceId,
}) {
  const { swatchesMap } = useColorSwatches();

  const [swiper, setSwiper] = useState(null);

  const { slider } = { ...similarProducts };
  const { sliderStyle } = { ...slider };
  const { section } = { ...similarProducts };
  const { productItem } = { ...similarProducts };
  const slidesPerViewDesktop = slider?.slidesPerViewDesktop || 4;
  const slidesPerViewTablet = slider?.slidesPerViewTablet || 3.4;
  const slidesPerViewMobile = slider?.slidesPerViewMobile || 1.4;
  const isFullBleedAndCentered =
    sliderStyle === 'fullBleed' || sliderStyle === 'fullBleedWithGradient';
  const isLoop = isFullBleedAndCentered || sliderStyle === 'containedWithLoop';
  const maxWidthClass =
    section?.fullWidth || isFullBleedAndCentered
      ? 'max-w-none'
      : 'max-w-[var(--content-max-lg-width)]';

  const [recommendedProducts, setRecommendedProducts] = useState([]);

  const fetchRecommendations = useCallback(async (productId) => {
    try {
      if (!productId) return;

      const endpoint = '/api/productSimilarRecommendations';
      const options = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: JSON.stringify({
          action: 'getProductSimilarRecommendations',
          productId,
        }),
      };

      const response = await fetch(endpoint, options);
      const data = await response.json();
      // Extract the 'results' array directly and set it in the state
      const results = data[0]?.results || [];
      setRecommendedProducts(results);
    } catch (error) {
      console.error(`fetchRecommendations error: ${error.message}`);
      throw error;
    }
  }, []);

  useEffect(() => {
    // Call the fetchRecommendations function with the provided productId
    fetchRecommendations(legacyResourceId);
  }, [fetchRecommendations, legacyResourceId]);

  return recommendedProducts?.length > 0 && (
    <div
      className={`${section?.spacing} ${
        !isFullBleedAndCentered ? 'lg:px-contained' : ''
      }`}
    >
      <div className="m-auto flex flex-col max-md:items-center">
        <div className="flex w-full items-center gap-5 px-5">
          <h2 className="text-title-h2 text-left">
            {similarProducts?.heading}
          </h2>
        </div>
        <Swiper
          modules={[A11y, Navigation, Keyboard]}
          a11y={{ enabled: true }}
          centeredSlides={
            isFullBleedAndCentered && slidesPerViewMobile * 2 <= 5
          }
          className={`relative mt-5 w-full md:mt-10 ${maxWidthClass} ${
            sliderStyle === 'fullBleedWithGradient'
              ? 'before:swiper-offset-gradient-270-left after:swiper-offset-gradient-270-right'
              : ''
          }`}
          grabCursor
          keyboard={{
            enabled: true,
            onlyInViewport: true,
          }}
          loop={isLoop && slidesPerViewMobile * 2 <= 5}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            disabledClass: 'cursor-not-allowed opacity-20',
          }}
          onSwiper={setSwiper}
          slidesOffsetAfter={isFullBleedAndCentered ? 0 : 20}
          slidesOffsetBefore={isFullBleedAndCentered ? 0 : 20}
          slidesPerView={slidesPerViewMobile}
          spaceBetween={20}
          breakpoints={{
            768: {
              centeredSlides:
                isFullBleedAndCentered && slidesPerViewTablet * 2 <= 5,
              loop: isLoop && slidesPerViewTablet * 2 <= 5,
              slidesPerView: slidesPerViewTablet,
              slidesOffsetBefore: isFullBleedAndCentered ? 0 : 40,
              slidesOffsetAfter: isFullBleedAndCentered ? 0 : 40,
            },
            1024: {
              centeredSlides:
                isFullBleedAndCentered && slidesPerViewDesktop * 2 <= 5,
              loop: isLoop && slidesPerViewDesktop * 2 <= 5,
              slidesPerView: slidesPerViewDesktop,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
          }}
          role="region"
          aria-label={`${similarProducts?.heading} Slider`}
          tabIndex="0"
        >
          {swiper &&
            recommendedProducts.map((product, index) => {
              const { url } = product?.mappings?.core;
              const cleanUrl = url
                ? url.substring(
                    url.lastIndexOf('/products/') + '/products/'.length
                  )
                : '';

              return (
                <SwiperSlide
                  key={`product-similar-${index}`}
                  className="pb-px md:h-auto"
                >
                  <ProductItem
                    key={`${product.id}-${index}`}
                    enabledColorNameOnHover={
                      productItem?.enabledColorNameOnHover
                    }
                    enabledColorSelector={productItem?.enabledColorSelector}
                    enabledQuickShop={productItem?.enabledQuickShop}
                    enabledStarRating={productItem?.enabledStarRating}
                    enabledProductType={productItem?.enabledProductType}
                    handle={cleanUrl}
                    index={index}
                    swatchesMap={swatchesMap}
                  />
                </SwiperSlide>
              );
            })}

          {slidesPerViewDesktop < 5 && (
            <div className="z-1 absolute left-0 right-0 top-[calc(50%-28px)] md:px-8 xl:px-14">
              <div
                className={`relative mx-auto ${maxWidthClass} ${
                  isFullBleedAndCentered ? 'min-[90rem]:max-w-full' : ''
                }`}
              >
                <div
                  className={`swiper-button-prev shadow-hairline left-0 top-[calc(50%-1.6875rem)] !hidden !h-[3.5rem] !w-[3.5rem] bg-white after:hidden lg:!flex ${
                    !isFullBleedAndCentered ? 'xl:-left-[1.6875rem]' : ''
                  }`}
                  aria-label="Previous Slide"
                  type="button"
                >
                  <Svg
                    className="w-[1.25rem] text-black"
                    src="/svgs/arrow-left.svg#arrow-left"
                    alt="Previous Slide Icon"
                    title="Arrow Left"
                    viewBox="0 0 24 24"
                  />
                </div>

                <div
                  className={`swiper-button-next shadow-hairline right-0 top-[calc(50%-1.6875rem)] !hidden !h-[3.5rem] !w-[3.5rem] bg-white after:hidden lg:!flex ${
                    !isFullBleedAndCentered ? 'xl:-right-[1.6875rem]' : ''
                  }`}
                  aria-label="Next Slide"
                  type="button"
                >
                  <Svg
                    className="w-[1.25rem] text-black"
                    src="/svgs/arrow-right.svg#arrow-right"
                    alt="Next Slide Icon"
                    title="Arrow Right"
                    viewBox="0 0 24 24"
                  />
                </div>
              </div>
            </div>
          )}
        </Swiper>
        {!swiper && (
          <div className="relative flex min-h-[20rem] items-center justify-center">
            <Spinner width="32" />
          </div>
        )}
      </div>
    </div>
  );
}

ProductSimilarRecommendations.displayName = 'ProductSimilarRecommendations';
