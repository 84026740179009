import { useCallback, useState } from 'react';
import { useCartItems, useRouter, useCustomer } from '@backpackjs/storefront';

import { useSearchspring } from './';
import { useSearchspringContext } from '../../contexts';
import { decodedId } from '../../utilities';

const LAST_VIEWED_KEY = `${
  process.env.SHOPIFY_DOMAIN?.split('.')[0]
}_last_viewed_product_ids`;

export const useSearchspringSearchResults = () => {
  const { requestSearchspring } = useSearchspring();
  const customer = useCustomer();
  const router = useRouter();
  const cartItems = useCartItems();
  const lastViewed =
    (typeof window !== 'undefined' &&
      window.localStorage.getItem(LAST_VIEWED_KEY)) ||
    '';

  const {
    state: { searchCurrentResultsPage, searchData, searchPageData, searchSelectedSort },
    actions: { setSearchData, setSearchPageData },
  } = useSearchspringContext();

  const [allPageResults, setAllPageResults] = useState([]);
  const [previousFilters, setPreviousFilters] = useState([]);
  const [previousSort, setPreviousSort] = useState(null);
  const [previousPage, setPreviousPage] = useState(1);

  const customerId = customer?.id || '';
  const cart = cartItems?.map((item) => item.variant.sku).join(',') || '';

  const { asPath } = router;

  // Get search results from query from ss
  const getSearchResults = useCallback(
    async ({ query, tag, isAutocomplete, isPage, selectedFilters }) => {
      try {
        if (isPage && !query && !tag) {
          setSearchPageData(null);
          return null;
        }

        if (!isPage && !query) {
          setSearchData(null);
          return null;
        }

        const shopper = decodedId(customerId)?.split('/').pop();

        const data = await requestSearchspring({
          action: 'getSearchResults',
          params: {
            query: query || '',
            resultsPerPage: isPage ? 48 : 10, // limit # of results shown; integer from 1-100
            page: isPage ? searchCurrentResultsPage : 1,
            isAutocomplete,
            domain: `${process.env.SITE_URL}/${asPath?.split('?')[0]}`, // full url of current page
            cart, // comma-separated list of product skus in the user's cart
            lastViewed, // comma-separated list of 5 most recent product ids or skus
            ...(isPage && !query && tag ? { tag } : null), // tag in url for landing pages
            ...(shopper ? { shopper } : null), // customer id (if logged in)
            ...(searchSelectedSort ? { sort: searchSelectedSort } : null), // sort order
            ...(isPage && selectedFilters
              ? { filters: selectedFilters }
              : null), // filters
          },
        });
        let _data = data;
        const banners = data?.merchandising?.content?.inline;
        if (isPage && banners?.length) {
          const resultsLength = _data.results?.length;
          const results = banners.reduce(
            (arr, item, index) => {
              const pos = item.config.position.index;
              if (resultsLength + index <= pos) return arr;
              return [
                ...arr.slice(0, pos),
                {
                  isBanner: true,
                  value: item.value,
                  id: `banner-index-${index}`,
                },
                ...arr.slice(pos),
              ];
            },
            [..._data.results]
          );
          _data = { ..._data, results };
        }

        if (isPage) {
          const currentPage = _data?.pagination?.currentPage;
          let newResults;
          if (JSON.stringify(selectedFilters) !== JSON.stringify(previousFilters) || JSON.stringify(searchSelectedSort) !== JSON.stringify(previousSort)) {
            newResults = [...(_data?.results || [])];
          } else {
            if (currentPage < previousPage) {
              newResults = [...(_data?.results || []), ...allPageResults];
            } else {
              newResults = [...allPageResults, ...(_data?.results || [])];
            }
          }
          setAllPageResults(newResults);
          setPreviousFilters(selectedFilters);
          setPreviousSort(searchSelectedSort);
          setPreviousPage(currentPage);
          setSearchPageData(_data || null);
        } else {
          setSearchData(_data || null);
        }

        return _data || null;
      } catch (error) {
        console.error(`Error with getSearchResults: ${error.message}`);
        throw error;
      }
    },
    [customerId, asPath, cart, lastViewed, searchCurrentResultsPage, searchSelectedSort]
  );

  return {
    state: {
      results: searchData?.results || [],
      totalResults: searchData?.pagination?.totalResults || 0,
      // pageResults: searchPageData?.results || [],
      pageResults: allPageResults,
      totalPageResults: searchPageData?.pagination?.totalResults || 0,
      merchandising: searchData?.merchandising || null,
      // id: searchData?.id || null,
    },
    actions: {
      getSearchResults,
      setSearchData,
      setSearchPageData,
    },
  };
};