import { useCallback, useEffect } from 'react';

import { useSearchspringContext } from '../../contexts';

import { updateFilterUrlParams } from './utils';

export const useSearchspringCollectionPagination = () => {
  const {
    state: { collection, currentResultsPage, selectedResultsPerPage, loadedPages },
    actions: { setCurrentResultsPage, setSelectedResultsPerPage, setLoadedPages },
  } = useSearchspringContext();

  const { previousPage, nextPage, totalPages } = collection?.pagination || {};

  const updateResultsPerPage = useCallback((num) => {
    if (!num) {
      return console.error(
        'updateResultsPerPage: an integer from 1-100 must be passed'
      );
    }
    setSelectedResultsPerPage(num);
  }, []);

  const goToPrevPage = useCallback(() => {
    if (!previousPage) return;
    const minLoadedPage = Math.min(...loadedPages);
    if (minLoadedPage === 1) return;

    const newPage = minLoadedPage - 1;
    const newPages = [...new Set([...loadedPages, newPage])];

    updateFilterUrlParams({
      page: newPage
    });

    setCurrentResultsPage(newPage);
    setLoadedPages(newPages);
  }, [previousPage]);

  const goToNextPage = useCallback(() => {
    if (!nextPage) return;
    const maxLoadedPage = Math.max(...loadedPages);
    if (maxLoadedPage === totalPages) return;

    const newPage = maxLoadedPage + 1;
    const newPages = [...new Set([...loadedPages, newPage])];

    updateFilterUrlParams({
      page: newPage
    });

    setCurrentResultsPage(newPage);
    setLoadedPages(newPages);
  }, [nextPage]);

  const goToPage = useCallback(
    (num) => {
      if (!num || num > totalPages) return;
      setCurrentResultsPage(num);
      setLoadedPages([num]);
    },
    [totalPages]
  );

  useEffect(() => {
    goToPage(1);
  }, []);

  return [
    // state
    {
      selectedResultsPerPage,
      currentResultsPage,
      totalPages: totalPages || 1,
      loadedPages,
    },
    // actions
    {
      updateResultsPerPage,
      goToPrevPage,
      goToNextPage,
      goToPage,
    },
  ];
};