export function Schema() {
  return {
    category: 'Media',
    label: 'Social Automated Gallery',
    key: 'social-automated-gallery',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/social-feed-preview.jpg?v=1675730338',
    fields: [
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Full width, full bleed',
        fields: [
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Bleed',
            name: 'fullBleed',
            component: 'toggle',
            description: 'Removes padding of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          fullWidth: false,
          fullBleed: true,
        },
      },
      {
        label: 'Video Settings',
        name: 'video',
        component: 'group',
        description: 'Video settings',
        fields: [
          {
            label: 'Autoplay',
            name: 'autoplay',
            component: 'toggle',
            description: 'Autoplays video when in view',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Loop',
            name: 'loop',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Pause & Play',
            name: 'pauseAndPlay',
            component: 'toggle',
            description: 'Enables pause and play button',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Sound',
            name: 'sound',
            component: 'toggle',
            description: 'Only applicable if autoplay is off',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Controls',
            name: 'controls',
            component: 'toggle',
            description: 'Shows native controls for video',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          autoplay: true,
          loop: true,
          pauseAndPlay: true,
          sound: false,
          controls: false,
        },
      },
      {
        label: 'Product Item Settings',
        name: 'productItem',
        component: 'group',
        description: 'Star rating, color variant selector, quick shop',
        fields: [
          {
            label: 'Enable Product Recommendations',
            name: 'enabled',
            component: 'toggle',
            description:
              'Enables product recommendations for post',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Product Recommendations Title',
            name: 'title',
            component: 'text',
            defaultValue: 'Shop the Look',
          },
          {
            label: 'Enable Star Rating',
            name: 'enabledStarRating',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Enable Color Variant Selector',
            name: 'enabledColorSelector',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },

          {
            label: 'Enable Color Name On Hover',
            name: 'enabledColorNameOnHover',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Enable Quick Shop',
            name: 'enabledQuickShop',
            component: 'toggle',
            description:
              'Quick shop is hidden on mobile and will only show if the product item has only one variant or multiple variants through a single option, e.g. "Size',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          enabledStarRating: false,
          enabledColorSelector: false,
          enabledColorNameOnHover: false,
          enabledQuickShop: false,
        },
      },
      {
        label: 'Slider Settings',
        name: 'slider',
        component: 'group',
        description: 'Slider style, slides per view',
        fields: [
          {
            label: 'Slider Style',
            name: 'sliderStyle',
            component: 'select',
            description:
              'Loop and centered settings only apply if the number of products is at least twice the number of slides per view',
            options: [
              { label: 'Contained', value: 'contained' },
              { label: 'Contained w/ Loop', value: 'containedWithLoop' },
              { label: 'Full Bleed, Centered w/ Loop', value: 'fullBleed' },
              {
                label: 'Full Bleed, Centered w/ Loop & Gradient',
                value: 'fullBleedWithGradient',
              },
            ],
          },
          {
            label: 'Slides Per View (desktop)',
            name: 'slidesPerViewDesktop',
            component: 'number',
          },
          {
            label: 'Slides Per View (tablet)',
            name: 'slidesPerViewTablet',
            component: 'number',
            description: 'Tip: use decimals to show partial slides',
          },
          {
            label: 'Slides Per View (mobile)',
            name: 'slidesPerViewMobile',
            component: 'number',
            description: 'Tip: use decimals to show partial slides',
          },
        ],
        defaultValue: {
          sliderStyle: 'contained',
          slidesPerViewDesktop: 4,
          slidesPerViewTablet: 3.4,
          slidesPerViewMobile: 1.4,
        },
      },
    ],
  };
}
