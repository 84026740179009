import { useEffect } from 'react';

import useSearch from '../../snippets/Collection/CollectionSearchspring/useSearch';

import { Spinner } from '../../snippets';
import { CollectionSearchspring } from '../../snippets/Collection/CollectionSearchspring';
import { useDataLayerActions } from '../../hooks';

export function SearchResultsSearchspring() {
  const {
    searchTerm,
    pageResults,
    totalProducts,
  } = useSearch();

    // Data layer actions
  const { sendViewSearchResultsEvent } = useDataLayerActions();
  
  useEffect(() => {
    sendViewSearchResultsEvent({
      products: pageResults,
      isSearchPage: true,
    });
  }, [pageResults, sendViewSearchResultsEvent]);

  return (
    <div className="px-contained py-contained">
      {pageResults?.length > 0 && (
        <>
          {searchTerm ? (
            <div className="py-contained !pt-0">
              <p className="text-body">Found <span className="font-medium">{totalProducts}</span> {totalProducts === 1 ? 'result' : 'results'} for:</p>
              <h1 className="text-title-h2">{searchTerm}</h1>
            </div>    
          ) : null }

          <CollectionSearchspring
            handle="search"
            products={pageResults}
          />
        </>
      )}

      {totalProducts === 0 && (
        <div className="py-contained !pt-0">
          <p className="text-body">Found 0 results for:</p>
          <h1 className="text-title-h2">{searchTerm || ''}</h1>
        </div>   
      )}

      {!Array.isArray(pageResults) && (
        <div className="flex min-h-[20rem] items-center justify-center">
          <Spinner width="32" />
        </div>
      )}
    </div>
  );
}

SearchResultsSearchspring.displayName = 'SearchResultsSearchspring';
