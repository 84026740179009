import { useCallback } from 'react';

import { useSearchspringContext } from '../../contexts';

import { updateFilterUrlParams } from './utils';

export const useSearchspringSearchSort = () => {
  const {
    state: { searchPageData, searchSelectedSort },
    actions: { setSearchSelectedSort, setSearchCurrentResultsPage, setSearchLoadedPages },
  } = useSearchspringContext();

  const sortCollection = useCallback(async ({ field, direction }) => {
    if (!field || !direction) {
      // clear sort
      setSearchSelectedSort(null);
      return;
    }

    // Examples
    // { field: 'ss_price', direction: 'asc' },
    // { field: 'title', direction: 'desc' }
    // { field: 'ss_days_since_published', direction: 'asc' }

    updateFilterUrlParams({
      keysToRemove: ['page'],
    });

    setSearchSelectedSort({ field, direction });
    setSearchCurrentResultsPage(1);
    setSearchLoadedPages([1]);
  }, []);

  return [
    // state
    {
      selectedSort: searchSelectedSort,
      sortOptions: searchPageData?.sorting?.options || null,
    },
    // actions
    {
      sortCollection,
    },
  ];
};