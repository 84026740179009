import PropTypes from 'prop-types';

import { CollectionSearchspring } from './CollectionSearchspring';
import { CollectionPack } from './CollectionPack';

export function Collection({
  handle = undefined,
  id = undefined,
  appEnabled,
  products = [],
  productsReady = true,
}) {
  const collectionComponent = appEnabled ? (
    <CollectionSearchspring
      handle={handle}
      id={id}
    />
  ) : (
    <CollectionPack
      handle={handle}
      id={id}
      productsReady={productsReady}
      products={products}
    />
  );
  return (
    <>
      {collectionComponent}
    </>
  );
}

Collection.displayName = 'Collection';
Collection.propTypes = {
  handle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  productsReady: PropTypes.bool,
};
