import { Fragment, useState } from 'react';

import { Link, ThreeTilesRow } from '../../snippets';
import { Schema } from './TabbedThreeTiles.schema';
import { TabbedThreeTilesTabs } from './TabbedThreeTilesTabs';

export function TabbedThreeTiles({ cms }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { button, heading, section, tabs } = cms;
  const { aspectRatio, bgColor, fullWidth, textColor } = { ...section };
  const maxWidthClass = fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-lg-width)]';

  return (
    <div
      className="lg:px-contained py-contained"
      style={{ backgroundColor: bgColor }}
    >
      {(heading || button?.text) && (
        <div className={`flex items-center justify-between gap-5 mb-5 mx-auto max-lg:px-5 xl:mb-10 ${maxWidthClass}`}>
          {heading && (
            <h2
              className="text-title-h2 text-left"
              style={{ color: textColor }}
            >
              {heading}
            </h2>
          )}
          {button?.text && (
            <Link
              aria-label={
                button?.newTab 
                  ? `Open in a new tab to view ${button?.text}` 
                  : button?.text
              }
              className={`flex ${section?.buttonStyle || 'btn-primary'}`}
              href={button.url}
              newTab={button.newTab}
              type={button.type}
            >
              {button.text}
            </Link>
          )}
        </div>
      )}

      <div className="mx-auto">
        <TabbedThreeTilesTabs
          activeTabIndex={activeTabIndex}
          maxWidthClass={maxWidthClass}
          setActiveTabIndex={setActiveTabIndex}
          tabs={tabs}
          textColor={textColor}
        />

        {tabs?.length > 0 &&
          tabs.map(({ tiles, itemsPerRow }, index) => {
            if (index !== activeTabIndex) return null;
            // console.log('tabs', tabs);
            return (
              <Fragment key={index}>
                <ThreeTilesRow
                  aspectRatio={aspectRatio}
                  maxWidthClass={maxWidthClass}
                  textColor={textColor}
                  tiles={tiles}
                  itemsPerRow={itemsPerRow}
                />
              </Fragment>
            );
          })}
      </div>
    </div>
  );
}

TabbedThreeTiles.displayName = 'TabbedThreeTiles';
TabbedThreeTiles.Schema = Schema;
