import { Link, TilesRow } from '../../snippets';
import { Schema } from './Tiles.schema';

export function Tiles({ cms }) {
  const { button, heading, hideHeading, section, tiles, itemsPerRow } = cms;
  const { aspectRatio, bgColor, fullWidth, spacing, textColor, iconColor } = { ...section };

  const maxWidthClass = fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-lg-width)]';

  return (
    <div
      className={spacing}
      style={{ backgroundColor: bgColor }}
    >
      {heading && !hideHeading && (
        <h2
          className="text-title-h2 mb-6 px-4 text-center md:mb-10"
          style={{ color: textColor }}
        >
          {heading}
        </h2>
      )}

      <TilesRow
        aspectRatio={aspectRatio}
        maxWidthClass={maxWidthClass}
        textColor={textColor}
        iconColor={iconColor}
        tiles={tiles}
        itemsPerRow={itemsPerRow}
      />

      {button?.text && (
        <div className="mt-10 flex flex-col items-center">
          <Link
            aria-label={
              button?.newTab 
                ? `Open in a new tab to view ${button?.text}` 
                : button?.text
            }
            className={`${section?.buttonStyle || 'btn-primary'}`}
            href={button.url}
            newTab={button.newTab}
            type={button.type}
          >
            {button.text}
          </Link>
        </div>
      )}
    </div>
  );
}

Tiles.displayName = 'Tiles';
Tiles.Schema = Schema;
