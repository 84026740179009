export function Schema() {
  return {
    category: 'Slider',
    label: 'Collection Slider',
    key: 'collection-slider',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/products-slider-preview.jpg?v=1675730335',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Collection Slider Heading',
      },
      {
        component: 'link',
        name: 'collection',
        label: 'Collection',
      },
      {
        label: 'Footer Button',
        name: 'button',
        component: 'link',
        defaultValue: {
          text: 'View All',
        }
      },
      {
        label: 'Product Item Settings',
        name: 'productItem',
        component: 'group',
        description: 'Star rating, color variant selector, quick shop',
        fields: [
          {
            label: 'Enable Star Rating',
            name: 'enabledStarRating',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Enable Color Variant Selector',
            name: 'enabledColorSelector',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },

          {
            label: 'Enable Color Name On Hover',
            name: 'enabledColorNameOnHover',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Enable Quick Shop',
            name: 'enabledQuickShop',
            component: 'toggle',
            description:
              'Quick shop is hidden on mobile and will only show if the product item has only one variant or multiple variants through a single option, e.g. "Size',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          enabledStarRating: true,
          enabledColorSelector: true,
          enabledColorNameOnHover: false,
          enabledQuickShop: true,
        },
      },
      {
        label: 'Slider Settings',
        name: 'slider',
        component: 'group',
        description: 'Slider style, slides per view',
        fields: [
          {
            label: 'Slider Style',
            name: 'sliderStyle',
            component: 'select',
            description:
              'Loop and centered settings only apply if the number of products is at least twice the number of slides per view',
            options: [
              { label: 'Contained', value: 'contained' },
              { label: 'Contained w/ Loop', value: 'containedWithLoop' },
              { label: 'Full Bleed, Centered w/ Loop', value: 'fullBleed' },
              {
                label: 'Full Bleed, Centered w/ Loop & Gradient',
                value: 'fullBleedWithGradient',
              },
            ],
          },
          {
            label: 'Slides Per View (desktop)',
            name: 'slidesPerViewDesktop',
            component: 'number',
          },
          {
            label: 'Slides Per View (tablet)',
            name: 'slidesPerViewTablet',
            component: 'number',
            description: 'Tip: use decimals to show partial slides',
          },
          {
            label: 'Slides Per View (mobile)',
            name: 'slidesPerViewMobile',
            component: 'number',
            description: 'Tip: use decimals to show partial slides',
          },
        ],
        defaultValue: {
          sliderStyle: 'contained',
          slidesPerViewDesktop: 5,
          slidesPerViewTablet: 3.4,
          slidesPerViewMobile: 1.4,
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Button style, full width',
        fields: [
          {
            label: 'Button Style',
            name: 'buttonStyle',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Secondary', value: 'btn-secondary' },
              { label: 'Inverse Light', value: 'btn-inverse-light' },
              { label: 'Inverse Dark', value: 'btn-inverse-dark' },
            ],
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width from contained styles',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Spacing',
            name: 'spacing',
            component: 'select',
            description: 'Addjust space around this section',
            options: [
              { label: 'Vertical padding', value: 'py-contained' },
              { label: 'Remove top padding', value: 'py-contained !pt-0' },
              { label: 'Remove bottom padding', value: 'py-contained !pb-0' },
            ],
          },
        ],
        defaultValue: {
          buttonStyle: 'btn-inverse-dark',
          fullWidth: true,
          spacing: 'py-contained',
        },
      },
    ],
  };
}
