import { useCallback, useState } from 'react';
import {
  useCartAddItem,
  useProductInventoryByHandle,
  useSettings,
} from '@backpackjs/storefront';

import { useGlobalContext } from '../contexts';

export function useAddToCart({
  addToCartText: addToCartTextOverride = '',
  isCartUpsell = false,
  quantity = 1,
  selectedVariant = null,
}) {
  const {
    actions: { openCart, openModal },
  } = useGlobalContext();
  const { cartAddItem } = useCartAddItem();
  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
    withInventory: false,
  });
  const settings = useSettings();

  const [isAdding, setIsAdding] = useState(false);

  const isLoading = !inventoryFetched;
  const variantInventory = inventory?.variants?.[selectedVariant?.id];
  const variantIsSoldOut =
    !isLoading && variantInventory && !variantInventory.availableForSale;
  const variantIsPreorder =
    !variantIsSoldOut && variantInventory?.currentlyNotInStock;
  const enabledNotifyMe = settings?.product?.backInStock?.enabled;

  let buttonText = '';
  if (variantIsPreorder) {
    buttonText = settings?.product?.addToCart?.preorderText || 'Preorder';
  } else if (variantIsSoldOut) {
    buttonText = enabledNotifyMe
      ? settings?.product?.backInStock?.notifyMeText || 'Notify Me'
      : settings?.product?.addToCart?.soldOutText || 'Sold Out';
  } else {
    buttonText =
      addToCartTextOverride ||
      settings?.product?.addToCart?.addToCartText ||
      'Add To Cart';
  }

  const handleAddToCart = useCallback(async () => {
    if (!selectedVariant?.id || isLoading || isAdding) return;
    setIsAdding(true);
    await cartAddItem({
      merchandiseId: selectedVariant.id,
      quantity,
    });
    setIsAdding(false);
    if (!isCartUpsell) openCart();
  }, [isAdding, isCartUpsell, isLoading, quantity, selectedVariant?.id]);

  const handleNotifyMe = useCallback(
    async (component) => {
      if (!selectedVariant?.id || isLoading) return;
      openModal(component);
    },
    [isLoading, selectedVariant?.id]
  );

  return {
    state: {
      buttonText,
      isAdding,
      isLoading,
      isNotifyMe: variantIsSoldOut && enabledNotifyMe,
      isSoldOut: variantIsSoldOut,
      subtext: settings?.product?.addToCart?.subtext,
    },
    actions: {
      handleAddToCart,
      handleNotifyMe,
    },
  };
}
