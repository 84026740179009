import { useSettings } from '@backpackjs/storefront';

import { FuseSearch } from './FuseSearch';
import { SearchspringSearch } from './SearchspringSearch';

export function Search() {
  const settings = useSettings();
  const appEnabled = settings?.search?.appEnabled;

  return (
    <>
      {appEnabled ? (
        <SearchspringSearch />
      ) : (
        <FuseSearch />
      )}
    </>
  );
}

Search.displayName = 'Search';
