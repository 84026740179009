import { useState } from 'react';
import { A11y, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Spinner } from '../../snippets';
import { ImageTile } from './ImageTile';
import { Schema } from './ImageTiles.schema';

export function ImageTiles({ cms }) {
  const { content, heading, hideHeading, section, tiles, itemsPerRow } = cms;

  const [swiper, setSwiper] = useState(null);

  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-lg-width)]';

  return (
    <div className={section?.spacing}>
      <div className={`mx-auto ${maxWidthClass}`}>
        {heading && !hideHeading && (
          <h2 className="text-title-h2 mb-6 px-4 text-center md:mb-10">
            {heading}
          </h2>
        )}

        {tiles?.length > 0 && (
          <>
            {/* mobile/tablet */}
            <div
              className={`relative lg:hidden ${!swiper ? 'min-h-[25rem]' : ''}`}
            >
              <Swiper
                modules={[A11y, Keyboard]}
                a11y={{ enabled: true }}
                grabCursor
                keyboard={{
                  enabled: true,
                  onlyInViewport: true,
                }}
                onSwiper={setSwiper}
                slidesOffsetAfter={0}
                slidesOffsetBefore={0}
                slidesPerView={1.4}
                spaceBetween={20}
                breakpoints={{
                  768: {
                    slidesPerView: tiles.length < 6 ? 1.4 : 2.4,
                  },
                }}
                role="region"
                aria-label={`${heading} Slider`}
                tabIndex="0"
              >
                {swiper &&
                  tiles.slice(0, 6).map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <ImageTile
                          aspectRatio={section?.aspectRatio}
                          content={content}
                          item={item}
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>

              {!swiper && (
                <Spinner
                  width="32"
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                />
              )}
            </div>

            {/* desktop */}
            <div
              className={`hidden gap-x-5 lg:grid ${itemsPerRow} 2xl:gap-10`}
            >
              {/* hidden div: to add tailwind classes for grid */}
              <div className="hidden grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6"></div>
              {tiles.slice(0, 6).map((item, index) => {
                return (
                  <div className="relative" key={index}>
                    <ImageTile
                      aspectRatio={section?.aspectRatio}
                      content={content}
                      item={item}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

ImageTiles.displayName = 'ImageTiles';
ImageTiles.Schema = Schema;
