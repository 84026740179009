export const updateFilterUrlParams = ({
  entriesToAdd = [],
  entriesToRemove = [],
  keysToRemove = [],
  page,
}) => {
  const { origin, search, pathname } = window.location;
  const params = new URLSearchParams(search);

  entriesToAdd.forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(singleValue => {
        params.append(key, singleValue);
      });
    } else {
      params.append(key, value);
    }
  });

  entriesToRemove.forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(singleValue => {
        params.delete(key, singleValue);
      });
    } else {
      params.delete(key, value);
    }
  });

  keysToRemove.forEach((key) => {
    params.delete(key);
  });

  if (page !== undefined) {
    params.set('page', page);
  }
  
  const updatedUrl = `${origin}${pathname}?${params}`;
  window.history.replaceState(window.history.state, '', updatedUrl);
};