import { useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { A11y, Navigation, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useColorSwatches } from '../../hooks';

import { ProductItem, Image, Svg, Spinner } from '../../snippets';

export function SocialAutomatedGalleryPost({
    section,
    item, 
    video, 
    productItem,
    slider,
  }) {
  const videoRef = useRef(null);
  const { ref: inViewRef, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: true,
  });
  const [isPlaying, setIsPlaying] = useState(video?.autoplay);

  const { swatchesMap } = useColorSwatches();

  const [swiper, setSwiper] = useState(null);

  const { sliderStyle } = { ...slider };
  const slidesPerViewDesktop = slider?.slidesPerViewDesktop || 4;
  const slidesPerViewTablet = slider?.slidesPerViewTablet || 3.4;
  const slidesPerViewMobile = slider?.slidesPerViewMobile || 1.4;
  const isFullBleedAndCentered =
    sliderStyle === 'fullBleed' || sliderStyle === 'fullBleedWithGradient';
  const isLoop = isFullBleedAndCentered || sliderStyle === 'containedWithLoop';
  const maxWidthClass =
    section?.fullWidth || isFullBleedAndCentered
      ? 'max-w-none'
      : 'max-w-[var(--content-max-lg-width)]';

  return (
    <div className="grid md:grid-cols-2">
      <div className="relative h-full w-full overflow-hidden">
        {item?.original_image_url ? (
          <Image
            alt={item?.post?.content}
            fill={false}
            sizes="100vw"
            width={1024}
            height={1024}
            src={`https:${item?.original_image_url}`}
          />
        ) : (
          <span className="group h-full w-full" ref={inViewRef}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              aria-label={item?.post?.content}
              autoPlay={video?.autoplay}
              className={`h-full w-full object-cover ${
                video?.pauseAndPlay ? 'cursor-pointer' : ''
              }`}
              controls={video?.controls}
              loop={video?.loop}
              muted={!video?.sound}
              onClick={() => {
                if (!video?.pauseAndPlay) return;
                if (isPlaying) {
                  videoRef.current.pause();
                  setIsPlaying(false);
                } else {
                  videoRef.current.play();
                  setIsPlaying(true);
                }
              }}
              playsInline
              poster={`https:${item?.thumbnail_url}/type/3/start_sec/1/end_sec/3/width/1920/height/1920/vid_slices/1/file_name/thumbnail.jpg`}
              ref={videoRef}
            >
              {inView && <source src={item?.standard_resolution_url} type="video/mp4" />}
            </video>

            {video?.pauseAndPlay && isPlaying && (
              <Svg
                className="pointer-events-none absolute top-1/2 left-1/2 w-10 -translate-x-1/2 -translate-y-1/2 text-white opacity-0 transition group-hover:opacity-90"
                src="/svgs/pause.svg#pause"
                alt="Pause Video Icon"
                title="Pause"
                viewBox="0 0 24 24"
              />
            )}

            {video?.pauseAndPlay && !isPlaying && (
              <Svg
                className="pointer-events-none absolute top-1/2 left-1/2 w-10 -translate-x-1/2 -translate-y-1/2 text-white opacity-70 transition group-hover:opacity-90"
                src="/svgs/play.svg#play"
                alt="Play Video Icon"
                title="Play"
                viewBox="0 0 24 24"
              />
            )}
          </span>
        )}
      </div>
      <div className="w-full overflow-hidden">
        <div className="p-5">
          <div className="flex gap-1">
            <Svg
              className="w-4 text-primary"
              src={`/svgs/noprecache/social/instagram.svg#instagram`}
              alt={`Instagram Icon`}
              title="Instagram"
              viewBox="0 0 24 24"
            />
            <p className="text-title-h4">Rhoback</p>
          </div>
          <p>{item?.post?.content}</p>
        </div>
        {productItem?.enabled && item?.tagged_products.length > 0 && (
          <div
            className={`w-full pb-5 ${
              !isFullBleedAndCentered ? 'px-5' : ''
            }`}
          >
            <div className="m-auto">
              <div className="flex w-full items-center">
                <p className="text-title-h5">{productItem?.title}</p>
              </div>
              <Swiper
                modules={[A11y, Navigation, Keyboard]}
                a11y={{ enabled: true }}
                centeredSlides={
                  isFullBleedAndCentered && slidesPerViewMobile * 2 <= 5
                }
                className={`relative mt-2.5 w-full ${maxWidthClass} ${
                  sliderStyle === 'fullBleedWithGradient'
                    ? 'before:swiper-offset-gradient-270-left after:swiper-offset-gradient-270-right'
                    : ''
                }`}
                grabCursor
                keyboard={{
                  enabled: true,
                  onlyInViewport: true,
                }}
                loop={isLoop && slidesPerViewMobile * 2 <= 5}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                  disabledClass: 'cursor-not-allowed opacity-20',
                }}
                onSwiper={setSwiper}
                slidesOffsetAfter={isFullBleedAndCentered ? 0 : 0}
                slidesOffsetBefore={isFullBleedAndCentered ? 0 : 0}
                slidesPerView={slidesPerViewMobile}
                spaceBetween={20}
                breakpoints={{
                  768: {
                    centeredSlides:
                      isFullBleedAndCentered && slidesPerViewTablet * 2 <= 5,
                    loop: isLoop && slidesPerViewTablet * 2 <= 5,
                    slidesPerView: slidesPerViewTablet,
                    slidesOffsetBefore: isFullBleedAndCentered ? 0 : 0,
                    slidesOffsetAfter: isFullBleedAndCentered ? 0 : 0,
                  },
                  1024: {
                    centeredSlides:
                      isFullBleedAndCentered && slidesPerViewDesktop * 2 <= 5,
                    loop: isLoop && slidesPerViewDesktop * 2 <= 5,
                    slidesPerView: slidesPerViewDesktop,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                  },
                }}
                role="region"
                aria-label={`${productItem?.title} Slider`}
                tabIndex="0"
                wrapperClass="max-md:min-w-full max-md:w-[80vw]"
              >
                {swiper &&
                  item?.tagged_products.map((product, index) => {
                    const url = product?.product_url;
                    const cleanUrl = url
                      ? url.substring(
                          url.lastIndexOf('/products/') + '/products/'.length
                        )
                      : '';

                    return (
                      <SwiperSlide
                        key={`look-products-${index}`}
                        className="pb-px md:h-auto"
                      >
                        <ProductItem
                          key={`${product.id}-${index}`}
                          enabledColorNameOnHover={
                            productItem?.enabledColorNameOnHover
                          }
                          enabledColorSelector={productItem?.enabledColorSelector}
                          enabledQuickShop={productItem?.enabledQuickShop}
                          enabledStarRating={productItem?.enabledStarRating}
                          enabledProductType={productItem?.enabledProductType}
                          handle={cleanUrl}
                          index={index}
                          swatchesMap={swatchesMap}
                        />
                      </SwiperSlide>
                    );
                  })}

                {slidesPerViewDesktop < 5 && (
                  <div className="z-1 absolute left-0 right-0 top-[calc(50%-28px)] md:px-8 xl:px-14">
                    <div
                      className={`relative mx-auto ${maxWidthClass} ${
                        isFullBleedAndCentered ? 'min-[90rem]:max-w-full' : ''
                      }`}
                    >
                      <div
                        className={`swiper-button-prev shadow-hairline left-0 top-[calc(50%-1.6875rem)] !hidden !h-[3.5rem] !w-[3.5rem] bg-white after:hidden lg:!flex ${
                          !isFullBleedAndCentered ? 'md:-left-[.75rem] xl:-left-[2.5rem]' : ''
                        }`}
                        aria-label="Previous Slide"
                        type="button"
                      >
                        <Svg
                          className="w-[1.25rem] text-black"
                          src="/svgs/arrow-left.svg#arrow-left"
                          alt="Previous Slide Icon"
                          title="Arrow Left"
                          viewBox="0 0 24 24"
                        />
                      </div>

                      <div
                        className={`swiper-button-next shadow-hairline right-0 top-[calc(50%-1.6875rem)] !hidden !h-[3.5rem] !w-[3.5rem] bg-white after:hidden lg:!flex ${
                          !isFullBleedAndCentered ? 'md:-right-[.75rem] xl:-right-[2.5rem]' : ''
                        }`}
                        aria-label="Next Slide"
                        type="button"
                      >
                        <Svg
                          className="w-[1.25rem] text-black"
                          src="/svgs/arrow-right.svg#arrow-right"
                          alt="Next Slide Icon"
                          title="Arrow Right"
                          viewBox="0 0 24 24"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Swiper>
              {!swiper && (
                <div className="relative flex min-h-[20rem] items-center justify-center">
                  <Spinner width="32" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

SocialAutomatedGalleryPost.displayName = 'SocialAutomatedGalleryPost';