import { useState } from 'react';
import { A11y, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useMatchMedia } from '../../hooks';

import { Spinner } from '../Spinner';
import { TilesTile } from './TilesTile';

export function TilesRow({
  heading,
  aspectRatio,
  maxWidthClass,
  textColor,
  iconColor,
  tiles,
  itemsPerRow,
}) {
  const [swiper, setSwiper] = useState(null);

  const isMobileViewport = useMatchMedia('(max-width: 1023px)');

  const tilesLength = tiles?.length > 3 ? tiles?.length : 3;
  const tilesPerRow = itemsPerRow || 'grid-cols-3';

  return tiles?.length > 0 ? (
    <div className={`mx-auto ${maxWidthClass}`}>
      {isMobileViewport ? (
        <div className={`relative lg:hidden ${!swiper ? 'min-h-[25rem]' : ''}`}>
          <Swiper
            modules={[A11y, Keyboard]}
            a11y={{ enabled: true }}
            grabCursor
            keyboard={{
              enabled: true,
              onlyInViewport: true,
            }}
            onSwiper={setSwiper}
            slidesOffsetAfter={0}
            slidesOffsetBefore={0}
            slidesPerView={2.4}
            spaceBetween={16}
            breakpoints={{
              768: {
                slidesPerView: 3.4,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
                spaceBetween: 16,
              },
            }}
            role="region"
            aria-label={`${heading} Slider`}
            tabIndex="0"
          >
            {swiper &&
              tiles.slice(0, tilesLength).map((item, index) => {
                const hideMobile = item.hideMobile ?? false;
                const shouldHideMobile = hideMobile && isMobileViewport;

                return !shouldHideMobile && (
                  <SwiperSlide className="w-full h-auto" key={index}>
                    <TilesTile
                      aspectRatio={aspectRatio}
                      item={item}
                      textColor={textColor}
                    />
                  </SwiperSlide>
                );
              })
            }
          </Swiper>

          {!swiper && (
            <Spinner
              width="32"
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          )}
        </div>
      ) : (
        <div className={`hidden ${tilesPerRow} gap-x-5 lg:grid`}>
          {tiles.slice(0, tilesLength).map((item, blockIndex) => {
            return (
              <div key={blockIndex}>
                <TilesTile
                  aspectRatio={aspectRatio}
                  item={item}
                  textColor={textColor}
                  iconColor={iconColor}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  ) : null;
}

TilesRow.displayName = 'TilesRow';
