import { useCallback, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

export function useCollectionProducts({
  pagination = {},
  goToNextPage,
  goToPrevPage,
  currentPage,
  totalPages,
  searchLoadedPages,
}) {
  const prevPageRef = useRef(currentPage);
  const { ref: loadMoreRef, inView } = useInView({
    rootMargin: '600px',
    triggerOnce: false,
  });

  const minLoadedPage = Math.min(...searchLoadedPages);
  const maxLoadedPage = Math.max(...searchLoadedPages);

  const isInfiniteScroll =
    pagination?.enabled && pagination?.loadType === 'infinite';

  const loadMoreProducts = useCallback(() => {
    if (maxLoadedPage >= totalPages) return;
    if (!isInfiniteScroll) {
      goToNextPage();
      return;
    }
  }, [maxLoadedPage, currentPage, isInfiniteScroll, goToNextPage]);

  const loadPreviousProducts = useCallback(() => {
    if (minLoadedPage === 1) return;
    if (!isInfiniteScroll) {
      goToPrevPage();
      return;
    }
  }, [minLoadedPage, isInfiniteScroll, goToPrevPage]);

  // auto loads more products if infinite scroll is enabled
  useEffect(() => {
    if (!inView || !isInfiniteScroll) return;
    loadMoreProducts();
  }, [inView, isInfiniteScroll]);

  // Scroll to top when currentPage is 1
  useEffect(() => {
    if (prevPageRef.current > 1 && currentPage === 1) {
      window.scrollTo(0, 0);
    }
    prevPageRef.current = currentPage;
  }, [currentPage]);

  return {
    state: {
      isInfiniteScroll,
      minLoadedPage,
      maxLoadedPage,
      totalPages,
    },
    actions: {
      loadMoreProducts,
      loadPreviousProducts,
    },
    refs: {
      loadMoreRef,
    },
  };
}
