import { useState, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSearchspringCollection, useSearchspringCollectionSort, useSearchspringCollectionFilters, useSearchspringCollectionPagination } from '../../../hooks';

export default function useCollection() {
  const router = useRouter();
  const { isReady: routerIsReady, query } = router;

  const [filtersLoaded, setFiltersLoaded] = useState(false);

  // Searchspring collection
  const { products: searchspringProducts, totalProducts } = useSearchspringCollection();

  // Searchspring collection sort
  const [{ selectedSort, sortOptions }, { sortCollection }] = useSearchspringCollectionSort();

  // Searchspring collection filters
  const [
    { selectedFilters, filters, filterSummary },
    { addToSelectedFilters, removeFromSelectedFilters, clearSelectedFilters, loadSelectedFilters}
  ] = useSearchspringCollectionFilters();

  // Searchspring collection pagination
  const [
    { selectedResultsPerPage, currentResultsPage, totalPages, loadedPages },
    { goToPrevPage, goToNextPage, goToPage }
  ] = useSearchspringCollectionPagination();

  function withErrorHandling(callback) {
    return async (...args) => {
      try {
        await callback(...args);
      } catch (error) {
        console.error(`Error in ${callback.name} function`, error);
        // Handle the error (e.g., set an error state, retry the operation, etc.)
      }
    };
  }

  const selectSort = useCallback(withErrorHandling(async (field, direction) => {
    await sortCollection({ field, direction });
  }), [sortCollection]);
  
  const addToFilters = useCallback(withErrorHandling(async (field, option) => {
    addToSelectedFilters(field, option);
  }), [addToSelectedFilters]);
  
  const removeFromFilters = useCallback(withErrorHandling(async (field, option) => {
    removeFromSelectedFilters(field, option);
  }), [removeFromSelectedFilters]);
  
  const clearFilters = useCallback(withErrorHandling(async () => {
    clearSelectedFilters();
  }), [clearSelectedFilters]);
  
  const goToPreviousPage = useCallback(withErrorHandling(async () => {
    goToPrevPage();
  }), [goToPrevPage]);
  
  const goToFollowingPage = useCallback(withErrorHandling(async () => {
    goToNextPage();
  }), [goToNextPage]);
  
  const goToSpecificPage = useCallback(withErrorHandling(async (num) => {
    goToPage(num);
  }), [goToPage]);

  // Sets filters on page load
  useEffect(() => {
    if (!routerIsReady) return;
  
    const filtersFromParams = Object.entries(query).flatMap(([key, value]) => {
      if (key === 'handle' || key === 'term' || key === 'page') return [];
  
      return Array.isArray(value) ? value.map(v => ({ key, value: v })) : [{ key, value }];
    });
  
    if (filtersFromParams.length > 0) {
      loadSelectedFilters(filtersFromParams);
    }

    // If there's a page parameter in the URL, go to that page
    if (query.page) {
      goToPage(Number(query.page));
    }

    setFiltersLoaded(true);
  }, [routerIsReady, query]);

  return {
    searchspringProducts,
    totalProducts,
    selectedSort,
    sortOptions,
    selectSort,
    selectedFilters,
    filters,
    filterSummary,
    addToFilters,
    removeFromFilters,
    clearFilters,
    selectedResultsPerPage,
    currentResultsPage,
    totalPages,
    loadedPages,
    goToPreviousPage,
    goToFollowingPage,
    goToSpecificPage,
    filtersLoaded,
  };
}