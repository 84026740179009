import { useEffect, useCallback, useState } from 'react';
import { useRouter } from 'next/router';

import { useSearchspringSearchResults, useSearchspringSearchSort, useSearchspringSearchFilters, useSearchspringSearchPagination } from '../../../hooks';

export default function useSearch() {
  const router = useRouter();
  const { isReady: routerIsReady, query } = router;
  const searchTerm = query.term;

  const [filtersLoaded, setFiltersLoaded] = useState(false);

  // Searchspring search results
  const {
    state: { results, totalResults, pageResults, totalPageResults, merchandising },
    actions: { getSearchResults, setSearchData, setSearchPageData },
  } = useSearchspringSearchResults();

  // Searchspring search results sort
  const [{ selectedSort, sortOptions }, { sortCollection }] = useSearchspringSearchSort();

  // Searchspring search results filters
  const [
    { selectedFilters, filters, filterSummary },
    { addToSelectedFilters, removeFromSelectedFilters, clearSelectedFilters, loadSelectedFilters }
  ] = useSearchspringSearchFilters();

  // Searchspring search results pagination
  const [
    { resultsPerPage, currentPage, totalPages, searchLoadedPages },
    { goToPrevPage, goToNextPage, goToPage }
  ] = useSearchspringSearchPagination();

  function withErrorHandling(callback) {
    return async (...args) => {
      try {
        await callback(...args);
      } catch (error) {
        console.error(`Error in ${callback.name} function`, error);
        // Handle the error (e.g., set an error state, retry the operation, etc.)
      }
    };
  }

  const selectSort = useCallback(withErrorHandling(async (field, direction) => {
    await sortCollection({ field, direction });
  }), [sortCollection]);

  const addToFilters = useCallback(withErrorHandling(async (field, option) => {
    addToSelectedFilters(field, option);
  }), [addToSelectedFilters]);

  const removeFromFilters = useCallback(withErrorHandling(async (field, option) => {
    removeFromSelectedFilters(field, option);
  }), [removeFromSelectedFilters]);

  const clearFilters = useCallback(withErrorHandling(async () => {
    clearSelectedFilters();
  }), [clearSelectedFilters]);

  const goToPreviousPage = useCallback(withErrorHandling(async () => {
    goToPrevPage();
  }), [goToPrevPage]);

  const goToFollowingPage = useCallback(withErrorHandling(async () => {
    goToNextPage();
  }), [goToNextPage]);

  const goToSpecificPage = useCallback(withErrorHandling(async (num) => {
    goToPage(num);
  }), [goToPage]);

  // Sets filters on page load
  useEffect(() => {
    if (!routerIsReady) return;

    const filtersFromParams = Object.entries(query).flatMap(([key, value]) => {
      if (key === 'handle' || key === 'term' || key === 'page') return [];

      return Array.isArray(value) ? value.map(v => ({ key, value: v })) : [{ key, value }];
    });

    if (filtersFromParams.length > 0) {
      loadSelectedFilters(filtersFromParams);
    }

    // If there's a page parameter in the URL, go to that page
    if (query.page) {
      goToPage(Number(query.page));
    }

    setFiltersLoaded(true);
  }, [routerIsReady, query]);

  useEffect(() => {
    if (!filtersLoaded) return;

    getSearchResults({ query: searchTerm, isPage: true, selectedFilters })
    .then((data) => {
      setSearchData(data);
      setSearchPageData(data);
    })
    .catch((error) => {
      console.error(error);
    });
  }, [getSearchResults, searchTerm, selectedFilters, filtersLoaded]);

  return {
    searchTerm,
    results,
    totalResults,
    pageResults,
    totalProducts: totalPageResults,
    merchandising,
    selectedSort,
    sortOptions,
    selectSort,
    selectedFilters,
    filters,
    filterSummary,
    addToFilters,
    removeFromFilters,
    clearFilters,
    selectedResultsPerPage: resultsPerPage,
    currentResultsPage: currentPage,
    totalPages,
    loadedPages: searchLoadedPages,
    goToPreviousPage,
    goToFollowingPage,
    goToSpecificPage,
  };
}