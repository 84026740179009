import { useCallback, useMemo, useState } from 'react';
import { useCartAddItem } from '@backpackjs/storefront';
import PropTypes from 'prop-types';

import { Spinner } from '../../Spinner';
import { useGlobalContext } from '../../../contexts';

export function QuickShopOption({
  inventory,
  inventoryFetched,
  optionName,
  selectedProduct,
  selectedVariant,
  value,
  isCompact = false,
  isSoldOut = false,
}) {
  const {
    actions: { openCart },
  } = useGlobalContext();
  const { cartAddItem } = useCartAddItem();

  const [isAdding, setIsAdding] = useState(false);

  const variantToAdd = useMemo(() => {
    return selectedProduct.variants?.find((variant) => {
      const color = variant.selectedOptionsMap?.Color;
      return (
        variant.selectedOptionsMap[optionName] === value &&
        (!color || color === selectedVariant.selectedOptionsMap?.Color)
      );
    });
  }, [optionName, selectedProduct.id, selectedVariant.id, value]);

  const variantInventory = inventory?.variants?.[variantToAdd?.id];
  const variantIsSoldOut = isSoldOut
    ? true
    : inventoryFetched && !variantInventory?.availableForSale;
  // const variantIsPreorder =
  //   !variantIsSoldOut && variantInventory?.currentlyNotInStock;
  const disabled = !inventoryFetched || !variantToAdd;

  const handleAddToCart = useCallback(async () => {
    if (!variantToAdd?.id || !inventoryFetched || isAdding) return;
    setIsAdding(true);
    await cartAddItem({
      merchandiseId: variantToAdd.id,
      quantity: 1,
    });
    setIsAdding(false);
    openCart();
  }, [variantToAdd?.id, inventoryFetched, isAdding]);

  const validClass =
    disabled || variantIsSoldOut || isSoldOut
      ? 'cursor-not-allowed'
      : 'cursor-pointer hover:bg-primary hover:text-white';
  const unavailableClass = variantIsSoldOut
    ? 'text-gray overflow-hidden'
    : '';

  return (
    <button
      aria-label={value}
      className={`group/option flex h-full w-full items-center justify-center whitespace-nowrap text-center transition ${validClass} ${unavailableClass} ${
        isCompact
          ? 'text-sm'
          : 'text-title-h6 '
      }`}
      disabled={disabled || variantIsSoldOut}
      onClick={handleAddToCart}
      type="button"
    >
      {isAdding ? (
        <div className="text-mediumGray group-hover/option:text-white">
          <Spinner width="20" />
        </div>
      ) : (
        value
      )}
    </button>
  );
}

QuickShopOption.displayName = 'QuickShopOption';
QuickShopOption.propTypes = {
  inventory: PropTypes.object,
  inventoryFetched: PropTypes.bool,
  optionName: PropTypes.string,
  selectedProduct: PropTypes.object,
  selectedVariant: PropTypes.object,
  value: PropTypes.string,
  isCompact: PropTypes.bool,
  isSoldOut: PropTypes.bool,
};
