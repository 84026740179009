import { useCallback, useState } from 'react';
import { useCustomer, useSettings } from '@backpackjs/storefront';

export function BackInStockModal({ selectedVariant }) {
  const customer = useCustomer();
  const settings = useSettings();

  const { heading, subtext, submitText } = {
    ...settings?.product?.backInStock,
  };

  const [email, setEmail] = useState(customer?.email || '');
  const [successMessage, setSuccessMessage] = useState('');
  
  const klaviyoId = `$shopify:::$default:::${selectedVariant.legacyResourceId}`;

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    // back in stock integration here
    try {
      // Call the subscribeToBIS function with the entered email and product ID
      const endpoint = '/api/backInStock';
      const options = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          revision: '2023-07-15',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'subscribeToBIS',
          email,
          id: klaviyoId,
        }),
      };

      // await fetch(endpoint, options);
      const response = await fetch(endpoint, options);
      // console.log('Subscription response:', response); // Check response data in console after submiting form
      if (response.ok && response.status === 202) {
        setSuccessMessage('Thank you for subscribing! We will let you know when this item is back in stock.');
      } else {
        setSuccessMessage('Failed to subscribe. Please try again later.');
      }
    } catch (error) {
      console.error('Subscription error:', error.message);
      setSuccessMessage('Failed to subscribe. Please try again later.');
    }
    // closeModal();
  }, [email, klaviyoId]);

  return (
    <div className="flex flex-col gap-5 text-left">
      <div>
        <h2 className="text-title-h3">{heading}</h2>
        {subtext && subtext !== 'Default Title' &&  <p className="mt-2">{subtext}</p>}
      </div>

      <div>
        <h3 className="text-title-h4">{selectedVariant.product.title}</h3>
        {selectedVariant.title !== 'Default Title' && <p>{selectedVariant.title}</p>}
      </div>

      <form
        className="flex w-full flex-col"
        onSubmit={(e) => handleSubmit(e, klaviyoId)}
      >
        <label className="sr-only" htmlFor="email">Email:</label>
        <input
          id="email"
          className="input-text text-text md:max-w-[30rem]"
          name="email"
          onChange={handleEmailChange}
          placeholder="Enter your email..."
          required
          aria-required="true"
          type="email"
          autoComplete="email"
          value={email}
        />

        <button
          aria-label="Notify Me"
          className="btn-primary mt-3 max-md:w-full"
          type="submit"
        >
          {submitText}
        </button>
        {successMessage && (
          <p className="transition-all mt-2.5 text-base text-current md:text-sm">{successMessage}</p>
        )}
      </form>
    </div>
  );
}

BackInStockModal.displayName = 'BackInStockModal';