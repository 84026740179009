import { useCallback } from 'react';

import { useSearchspringContext } from '../../contexts';

import { updateFilterUrlParams } from './utils';

export const useSearchspringSearchPagination = () => {
  const {
    state: {
      searchPageData,
      searchCurrentResultsPage,
      searchSelectedResultsPerPage,
      searchLoadedPages,
    },
    actions: { 
      setSearchCurrentResultsPage, 
      setSearchSelectedResultsPerPage,
      setSearchLoadedPages, 
    },
  } = useSearchspringContext();

  const { previousPage, nextPage, totalPages } =
    searchPageData?.pagination || {};

  const updateResultsPerPage = useCallback((num) => {
    if (!num) {
      return console.error(
        'updateResultsPerPage: an integer from 1-100 must be passed'
      );
    }
    setSearchSelectedResultsPerPage(num);
  }, []);

  const goToPrevPage = useCallback(() => {
    if (!previousPage) return;
    const minLoadedPage = Math.min(...searchLoadedPages);
    if (minLoadedPage === 1) return;

    const newPage = minLoadedPage - 1;
    const newPages = [...new Set([...searchLoadedPages, newPage])];

    updateFilterUrlParams({
      page: newPage
    });

    setSearchCurrentResultsPage(newPage);
    setSearchLoadedPages(newPages);
  }, [previousPage]);

  const goToNextPage = useCallback(() => {
    if (!nextPage) return;
    const maxLoadedPage = Math.max(...searchLoadedPages);
    if (maxLoadedPage === totalPages) return;

    const newPage = maxLoadedPage + 1;
    const newPages = [...new Set([...searchLoadedPages, newPage])];

    updateFilterUrlParams({
      page: newPage
    });

    setSearchCurrentResultsPage(newPage);
    setSearchLoadedPages(newPages);
  }, [nextPage]);

  const goToPage = useCallback(
    (num) => {
      if (!num || num > totalPages) return;
      setSearchCurrentResultsPage(num);
      setSearchLoadedPages([num]);
    },
    [totalPages]
  );

  return [
    // state
    {
      resultsPerPage: searchSelectedResultsPerPage,
      currentPage: searchCurrentResultsPage,
      totalPages: totalPages || 1,
      searchLoadedPages,
    },
    // actions
    {
      updateResultsPerPage,
      goToPrevPage,
      goToNextPage,
      goToPage,
    },
  ];
};